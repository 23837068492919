import { useState, useEffect } from "react";
import NavBar from "./Navbar/Navbar";
import { Outlet } from "react-router-dom";
import AppFooter from "./AppFooter/AppFooter";
import Sidebar from "./Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { useSidebarContext } from "../hooks/useSidebarContext";
const AppLayout = () => {
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isOpen, setIsOpen } = useSidebarContext();

  const location = useLocation();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const isNavBackgroundWhite = location?.pathname !== "/";

  const path = location?.pathname;

  const hideNav =
    path == "/contact-us" || path === "/projects/project-view" || path === "/";

  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div
        className={`page-container
      ${isOpen ? "open" : ""}`}
      >
        {!hideNav ? (
          <NavBar
            toggleSidebar={toggleSidebar}
            isNavBackgroundWhite={isNavBackgroundWhite}
          />
        ) : null}
        <Outlet />
        <AppFooter />
      </div>
    </>
  );
};

export default AppLayout;
