import React from "react";
import "./InfoCard.css";

const InfoCard = ({ title, description, imgPath }) => {
  return (
    <div className="info-card-container">
      <div className="box">
        <div className="title-container">
          {imgPath ? <img src={imgPath} /> : null}
          <span className="title">{title}</span>
        </div>
        <div>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
