import React from "react";
import DefaultHeader from "../components/DefaultHeader/DefaultHeader";
import VerticalTimelineComponent from "../components/VerticalTimeline/VerticalTimelineComponent";

const WebBuilding = () => {
  const categories = [
    {
      name: "בניית אתרים",
    },
    {
      name: "בניית אתרים",
    },
    {
      name: "בניית אתרים",
    },

    {
      name: "בניית אתרים",
    },
  ];
  return (
    <div className="app-dev-container">
      <DefaultHeader title={"בניית אתרים"} categories={categories} />

      <VerticalTimelineComponent />
    </div>
  );
};

export default WebBuilding;
