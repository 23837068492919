import React, { useState } from "react";
import "./Sidebar.css";
import { IconButton } from "@mui/material";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import SocialCard from "../AppFooter/components/SocialCard";

import { GrFacebookOption } from "react-icons/gr";
import { BsInstagram } from "react-icons/bs";
import { BiLogoLinkedin } from "react-icons/bi";
import ReceiveQuoteButton from "../ReceiveQuoteButton/ReceiveQuoteButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaChevronDown } from "react-icons/fa";
import Typography from "@mui/material/Typography";
import { v4 as uuid } from "uuid";
const sideBarRoutes = [
  {
    title: "הפרויקטים שלנו",
    path: "/projects",
  },
  {
    title: "שירותים מקצועיים",
    path: "/about-us",
    children: [
      { title: "צוותי פיתוח מנוהלים", path: "/team" },
      {
        title: "פיתוח אפליקציות",
        path: "/about-us",
      },
      {
        title: "פיתוח תוכנה ואינטגרציה",
        path: "/about-us",
      },
      {
        title: "פיתוח תוכנה ואינטגרציה",
        path: "/about-us",
      },
      {
        title: "טרנספורמציה דיגיטלית",
        path: "/about-us",
      },
      {
        title: "עיצוב ממשק משתמש UI/UX",
        path: "/about-us",
      },
      {
        title: "פיתוח אתרים",
        path: "/about-us",
      },
      {
        title: "בניית אתרים ומערכות מורכבות",
        path: "/about-us",
      },
      {
        title: "עיצוב אתרים",
        path: "/about-us",
      },
      {
        title: "שירותי ענן AWS Amazon",
        path: "/about-us",
      },
    ],
  },
  {
    title: "אודתינו",
    path: "/about-us",
    children: [
      { title: "עובדים", path: "/team" },
      {
        title: "לקוחות",
        path: "/about-us",
      },
      {
        title: "חברותנו",
        path: "/about-us",
      },
    ],
  },
  {
    title: "צור קשר",
    path: "/contact-us",
  },
];
const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-inner-container">
        <div className="sidebar-close-btn">
          <IconButton color="white" onClick={toggleSidebar}>
            <IoCloseSharp size={40} color="white" />
          </IconButton>
        </div>
        <div className="navlist-container">
          <ul>
            {sideBarRoutes.map((route, index) => {
              return (
                <li key={uuid()}>
                  {!route?.children ? (
                    <Link to={route.path} style={{ textDecoration: "none" }}>
                      <span className="nav-text">{route.title}</span>
                    </Link>
                  ) : (
                    <Accordion
                      sx={{
                        background: "unset",
                        direction: "rtl",
                        boxShadow: "unset",
                        padding: 0,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<FaChevronDown color="white" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ padding: 0 }}
                      >
                        <span className="nav-text">{route.title}</span>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          padding: 0,
                          flexDirection: "column",
                          display: "flex",
                          gap: 2,
                          marginBottom: 4,
                        }}
                      >
                        {route?.children.map((childRoute, index) => {
                          return (
                            <Link
                              key={uuid()}
                              to={childRoute.path}
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <span className="nav-text nav-child-text">
                                {childRoute.title}
                              </span>
                            </Link>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </li>
              );
            })}
            {/* <li>הפרוייקטים שלנו</li>
            <li>צור קשר</li>
            <li>דרושים</li> */}
          </ul>
        </div>
      </div>
      <footer className="sidebar-footer">
        <div className="socials-container">
          <SocialCard
            Icon={GrFacebookOption}
            color={"white"}
            hoverColor="black"
          />
          <SocialCard Icon={BsInstagram} color={"white"} hoverColor="black" />
          <SocialCard
            Icon={BiLogoLinkedin}
            color={"white"}
            hoverColor="black"
          />
        </div>
        <div className="sidebar-footer-row">
          <span>04-603-0888</span>
          <ReceiveQuoteButton />
        </div>
      </footer>
    </div>
  );
};

export default Sidebar;
