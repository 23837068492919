import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//Pages
import { routes } from "./appRoutes";
import AppLayout from "../components/AppLayout";
import ProjectView from "../pages/Projects/sub-pages/ProjectView";
const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        {routes.map((route, index) => (
          <Route exact key={index} path={route.path}>
            <Route index={true} element={route.element} />
            {route.subs &&
              route.subs.map((subRoute, subIndex) => (
                <Route
                  key={subIndex}
                  path={subRoute.path}
                  element={subRoute.element}
                />
              ))}
          </Route>
        ))}
      </Route>
    </Routes>
  );
};
export default AppRoutes;
