import React from "react";

const HeroSection = () => {
  return (
    <section>
      <div className="projects-hero-section-container">
        <div className="projects-info-row">
          <h1>הפרויקטים שלנו</h1>
          <div className="separator"></div>
          <p>
            ב-Codit, אנחנו מתגאים בהתחייבות שלנו למספק אתרים ויישומים מצוינים
            <br />
            שמותאמים לצרכיך הייחודיים
          </p>
        </div>
        <div className="projects-info-row"></div>
      </div>
    </section>
  );
};

export default HeroSection;
