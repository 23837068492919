import React from "react";

const HeroSection = () => {
  return (
    <section className="team-hero-section">
      <div className="projects-hero-section-container">
        <div className="projects-info-row">
          <h1>צוות שלנו</h1>
          <div className="separator"></div>
          <p>פגוש את צוותנו המחשבה החדשנית מאחורי CODIT.</p>
        </div>
        <div className="projects-info-row"></div>
      </div>
    </section>
  );
};

export default HeroSection;
