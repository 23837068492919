import React from "react";
import HeroSection from "./components/HeroSection";
import ContactUsSection from "./components/ContactUsSection";
import "./Contactus.css";
import { useSidebarContext } from "../../hooks/useSidebarContext";
import NavBar from "../../components/Navbar/Navbar";
const Contactus = ({ children }) => {
  const { setIsOpen } = useSidebarContext();
  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="contact-us-page">
      {/* <HeroSection /> */}
      <div className="contact-us-page-container">
        <NavBar toggleSidebar={toggleSidebar} color="white" />
        <ContactUsSection />
      </div>
    </div>
  );
};

export default Contactus;
