import React, { useEffect } from "react";

import CustomeButton from "../../../components/CustomeButton";
import SectionInfo from "./SectionInfo/SectionInfo";
import { Slide } from "react-awesome-reveal";
import { slideIn } from "../../../utils/motion";
import styles from "../../../styles/style";
import { motion } from "framer-motion";
const ApplicationSection = () => {
  // useEffect(() => {
  //   const tween = KUTE?.fromTo(
  //     "#blob1",
  //     { path: "#blob1" },
  //     { path: "#blob2" },
  //     { repeat: 999, duration: 3000, yoyo: true }
  //   )?.start();
  // }, []);

  return (
    <>
      <section className="application-section">
        <div className="application-container">
          <Slide direction="left" triggerOnce={true}>
            <div className="application-left">
              <div className="mobile_grad"></div>

              <img src={require("../../../assets/imgs/mobile_img.png")} />
            </div>
          </Slide>
          <SectionInfo
            title={"MOBILE"}
            buttonLabel={"פרויקטים נוספים"}
            label={"פיתוח אפליקציות היברידיות | Ionic | React Native"}
            color={"#085078"}
            gradientStyle={{
              backgroundImage: "linear-gradient(to right, #085078, #85d8ce)",
            }}
            description={
              "ש לנו צוותי פיתוח אפליקציות מהמקצועיים ביותר בארץ, מומחים בפיתוח במגוון טכנולוגיות, היברידיות, PWA ו Flutter, בין אם זה עיצוב מותאם לחווית משתמש למובייל או פיתוח אפליקציה חוצה פלטפורמות. נעזור לכם להבין ולהחליט באיזה סוג טכנולוגיה להשתמש. אנחנו מזמינים אתכם להתרשם ממגוון הפרויקטים שלנו"
            }
          />
        </div>
      </section>
      {/* <div class="spacer layer1"></div> */}
      {/* <svg
        className="blob-motion"
        id="visual"
        viewBox="0 0 960 540"
        width="960"
        height="540"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
      >
        <g transform="translate(450.7256843113689 283.4942824330989)">
          <path
            id="blob1"
            d="M148.7 -134.9C193.7 -103.7 231.9 -51.9 232.4 0.6C233 53 196.1 106.1 151.1 128.6C106.1 151.1 53 143 -4.4 147.4C-61.8 151.8 -123.5 168.5 -151.2 146C-178.8 123.5 -172.4 61.8 -172.8 -0.4C-173.1 -62.5 -180.3 -124.9 -152.6 -156.1C-124.9 -187.3 -62.5 -187.1 -5.3 -181.8C51.9 -176.5 103.7 -166 148.7 -134.9"
            fill="#1abc9c"
          ></path>
        </g>
        <g
          transform="translate(509.54377535978017 281.49390864595887)"
          style={{ visibility: "hidden" }}
        >
          <path
            id="blob2"
            d="M115.4 -137.9C137.9 -92.9 136.4 -46.4 133.6 -2.8C130.8 40.8 126.6 81.6 104.1 118.4C81.6 155.2 40.8 188.1 -8.4 196.5C-57.5 204.8 -115 188.7 -151 151.9C-187 115 -201.5 57.5 -190.8 10.7C-180.1 -36.1 -144.1 -72.1 -108.1 -117.1C-72.1 -162.1 -36.1 -216.1 5.2 -221.2C46.4 -226.4 92.9 -182.9 115.4 -137.9"
            fill="#BB004B"
          ></path>
        </g>
      </svg> */}
    </>
  );
};
{
  /* <svg
  id="visual"
  viewBox="0 0 960 300"
  width="960"
  height="300"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  version="1.1"
>
  <g transform="translate(479.95216689412086 165.45616401471466)">
    <path
      d="M69.1 -69.1C93.5 -44.8 119.7 -22.4 125.6 5.9C131.5 34.2 117 68.4 92.7 83.9C68.4 99.4 34.2 96.2 8.2 87.9C-17.7 79.7 -35.4 66.4 -60.4 50.9C-85.4 35.4 -117.7 17.7 -125.1 -7.4C-132.5 -32.5 -115.1 -65.1 -90.1 -89.4C-65.1 -113.7 -32.5 -129.9 -5.1 -124.8C22.4 -119.7 44.8 -93.5 69.1 -69.1"
      fill="#1abc9c"
    ></path>
  </g>
</svg>; */
}
export default ApplicationSection;
