import React from "react";
import CustomeButton from "../../../../components/CustomeButton";
import PreyPaySection from "./PreyPaySection";

const HeroSection = () => {
  return (
    <section className="project-view-hero-section">
      <div className="project-view-hero-container">
        <div className="project-view-hero-left">
          <h1>קרן קהילות בישראל</h1>
          <article>
            קרן להתחדשות קהילות בישראל מהווה גורם מכוון ומסייע לגרעינים, על פי
            צרכיהם של הגרעינים. הקרן מרכזת טיפול בעבודה מול גורמים ציבוריים
            וכלכליים ובנוסף מלווה את הגרעינים בייעוץ ארגוני על פי ידע שנצבר
            בגרעינים קודמים. כמו כן, פועלת קרן קהילות לגיוס משאבים עבור הגרעינים
            ולחקיקה ותקינה ממשלתית לקידום פעולתם. חזון הקרן הוא ליצור תנועה
            גדולה שתרומם את הרוח, תעצים את הכוחות הערכיים ותביא תנופה חינוכית
            וחברתית ברחבי קהילות בישראל. עבור קרן קהילות פתחנו אתר חדשני ומתקדם
            להצגת מפעלות הקרן, הגרעינים הפרוסים ברחבי הארץ והפעילות השוטפת של
            הקרן.
          </article>
          <CustomeButton text={"לצפייה בפרויקט"} color={"#080c60"} />
        </div>
        <div className="project-view-hero-right">
          <PreyPaySection />
          {/* <img src={require("../../../../assets/imgs/project2.png")} /> */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
