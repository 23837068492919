import React from "react";
import Marquee from "react-fast-marquee";
import MarqueeContent from "./MarqueeContent";
import "./Marquee.css";
const MarqueeView = () => {
  return (
    <div className="marquees-container">
      <div className="line-1">
        <Marquee className="marquee-container marquee-left" speed={25}>
          <MarqueeContent />
        </Marquee>
      </div>
      <div className="line-2">
        <Marquee
          className="marquee-container marquee-right"
          direction="right"
          speed={25}
        >
          <MarqueeContent />
        </Marquee>
      </div>
    </div>
  );
};

export default MarqueeView;
