import React from "react";
import ProjectCard from "../../../components/ProjectCard/ProjectCard";
import { Grid } from "@mui/material";
import { projects } from "../../../utils/data";

const ProjectsList = () => {
  return (
    <section className="projects-list-section">
      <div className="projects-list-container">
        {projects.map((project, index) => (
          <ProjectCard
            number={index}
            description={project.description}
            key={index}
            imgPath={project.img}
            color={project.color}
          />
        ))}
      </div>
    </section>
  );
};

export default ProjectsList;
