import React from "react";
import { HiArrowLeft } from "react-icons/hi";
const CustomeButton = ({ text, color }) => {
  const buttonStyle = {
    "--c": color,
  };
  return (
    <div className="custome-btn" style={buttonStyle}>
      <ul>
        <li>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <HiArrowLeft size={18} />
          {text}
        </li>
      </ul>
    </div>
  );
};

export default CustomeButton;
