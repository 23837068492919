import React, { useState } from "react";
import "./AppFooter.css";
import ReceiveQuoteButton from "../ReceiveQuoteButton/ReceiveQuoteButton";
import SocialCard from "./components/SocialCard";
import { GrFacebookOption } from "react-icons/gr";
import { BsInstagram } from "react-icons/bs";
import { BiLogoLinkedin } from "react-icons/bi";
import { Col, Divider, Row } from "antd";
import { Menu } from "antd";
import { BsAppIndicator } from "react-icons/bs";
import { MdOutlineWeb } from "react-icons/md";
import { HiServerStack } from "react-icons/hi2";
import { MdOutlineSettingsApplications } from "react-icons/md";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { MOBILE_PHONE } from "../../constants/AppConstants";

const options = [
  {
    items: [
      getItem("פיתוח אפליקציות", "1", <BsAppIndicator size={16} />, [
        getItem(
          null,
          "g1",
          null,
          [
            getItem("פיתוח אפליקציות", "app-dev"),
            getItem("פיתוח אפליקציות היברידיות", "app-dev"),
            getItem("פיתוח אפליקציות Flutter", "app-dev"),
            getItem("פיתוח אפליקציות PWA", "app-dev"),
            getItem("בניית אסטרטגייה דיגיטלית", "app-dev"),
          ],
          "group"
        ),
      ]),
    ],
  },
  {
    items: [
      getItem("פיתוח אתרים", "2", <MdOutlineWeb size={20} />, [
        getItem(
          null,
          "g1",
          null,
          [
            getItem("פיתוח אתרים", "web-dev"),
            getItem("פיתוח אתרים מקצועי", "web-dev"),
            getItem("צוותי פיתוח מנוהלים", "web-dev"),
          ],
          "group"
        ),
      ]),
    ],
  },
  {
    items: [
      getItem(
        "עיצוב UI/UX",
        "3",
        <img
          src={require("../../assets/icons/layer.png")}
          className="footer-menu-icon"
        />,
        [
          getItem(
            null,
            "g1",
            null,
            [
              getItem("עיצוב ממשק משתמש לאפליקציה", "web-design"),
              getItem("עיצוב ממשק משתמש לאתר אינטרנט", "web-design"),
              getItem("חברת עיצוב ממשק משתמש למערכת", "web-design"),
            ],
            "group"
          ),
        ]
      ),
    ],
  },
  {
    items: [
      getItem(
        "אפיון אתר",
        "4",
        <img
          src={require("../../assets/icons/presentation.png")}
          className="footer-menu-icon"
        />,
        [
          getItem(
            null,
            "g1",
            null,
            [
              getItem("אפיון אתר אינטרנט", "internet-web-characterization"),
              getItem("אפיון אתר", "web-characterization"),
              getItem("אפיון מערכת", "system-characterization"),
              getItem("אפיון אתר", "web-characterization"),
              getItem("אפיון אפליקציה", "app-characterization"),
            ],
            "group"
          ),
        ]
      ),
    ],
  },
  {
    items: [
      getItem("שירותי ענן", "5", <HiServerStack size={20} />, [
        getItem(
          null,
          "g1",
          null,
          [getItem("שירותי ענן AWS Amazon", "cloud-services")],
          "group"
        ),
      ]),
    ],
  },

  {
    items: [
      getItem(
        "אודותינו",
        "6",
        <img
          src={require("../../assets/icons/information.png")}
          className="footer-menu-icon"
        />,
        [
          getItem(
            null,
            "g1",
            null,
            [
              getItem("אודותינו", "about-us"),
              getItem("הפרוייקטים שלנו", "projects"),
              getItem("צוות", "team"),
              getItem("דרושים", "needed"),
            ],
            "group"
          ),
        ]
      ),
    ],
  },
  {
    items: [
      getItem(
        "פיתוח תוכנה ואינטגרציה",
        "6",
        <MdOutlineSettingsApplications size={20} />,
        [
          getItem(
            null,
            "g1",
            null,
            [
              getItem(
                "פיתוח תוכנה | בית תוכנה | אינטגרציות למערכות",
                "about-us"
              ),
              getItem("טכנולוגיות פיתוח תוכנה", "projects"),
            ],
            "group"
          ),
        ]
      ),
    ],
  },
];
const AppFooter = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(["app-dev"]);
  const navigate = useNavigate();
  const handleMenuClick = (e, index) => {
    setActiveMenuItem(e);
  };

  const handleClickItem = (path) => {
    navigate(path);
    setActiveMenuItem([]);
  };
  return (
    <footer className="app-footer">
      <div className="app-footer-container">
        <div className="footer-top">
          <div className="footer-top-left">
            <p>
              <a href="tel:+6030888">{MOBILE_PHONE}</a>
            </p>
            <ReceiveQuoteButton />
          </div>
          <div className="socials-container">
            <SocialCard Icon={GrFacebookOption} color={"#43619B"} />
            <SocialCard Icon={BsInstagram} color={"#E7385F"} />
            <SocialCard Icon={BiLogoLinkedin} color={"#197FB7"} />
          </div>
        </div>
        <main className="footer-main-content">
          <Grid
            container
            spacing={{ xs: 12, md: 8 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ direction: "rtl", maxWidth: 1500, width: "100%" }}
          >
            {options.map((option, index) => (
              <Grid item xs={2} sm={2} md={3} key={uuid()}>
                <Menu
                  style={{
                    background: "transparent",
                  }}
                  // openKeys={[option?.items?.[0]?.key]}
                  openKeys={
                    activeMenuItem?.[0] === option?.items?.[0]?.key
                      ? activeMenuItem
                      : []
                  }
                  mode="inline"
                  items={option?.items}
                  // selectedKeys={[activeMenuItem]}
                  onOpenChange={(e) => handleMenuClick(e, index)}
                  // openKeys={activeMenuItem}
                  onClick={(e) => handleClickItem(e?.keyPath?.[0])}
                />
              </Grid>
            ))}
          </Grid>
        </main>
      </div>
    </footer>
  );
};

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export default AppFooter;
