import React from "react";
import Ellipse from "../../../components/Ellipse/Ellipse";

const milestonesList = [
  {
    title: "שנות וותק",
    value: "7",
    icon: require("../../../assets/icons/calendar.png"),
  },
  {
    title: "פרויקטים",
    value: "100",
    icon: require("../../../assets/icons/project-management.png"),
  },
  {
    title: "עובדים",
    value: "20",
    icon: require("../../../assets/icons/link.png"),
  },
  {
    title: "כוסות קפה",
    value: "10k",
    icon: require("../../../assets/icons/coffee-cup.png"),
  },
];

const HeroSection = () => {
  return (
    <section className="abouts-us-hero-section">
      <div className="abouts-us-hero-container">
        <div className="abouts-us-hero-right">
          <span>אודות</span>
          <h1>
            חברתנו היא חברת תוכנה מתקדמת שמתמחה בפתרונות
            <br />
            חדשניים ובלתי רגילים
          </h1>
          <p>
            מחויבים לספק ללקוחותינו פתרונות חדשניים שמתאימים לדרישותיהם
            הייחודיות. בתוך צוותנו ישנה יכולת רבה לפתח תוכנה מתקדמת ביותר ולהביא
            רעיונות לחיים. אנו נודעים באיכות הגבוהה של מוצרינו ובשירות לקוחות
            מצוינת.
          </p>
        </div>
        <div className="abouts-us-hero-left">
          {/* <div className="abouts-us-hero-ellipse">
            <Ellipse
              imgPath={require("../../../assets/icons/Programmer-amico.png")}
            />
          </div> */}

          <div className="milestones-wrapper">
            <div className="milestones-container">
              {milestonesList.map((milestone, index) => {
                return (
                  <MilestoneCard
                    key={milestone?.title}
                    value={milestone.value}
                    title={milestone.title}
                    icon={milestone.icon}
                  />
                );
              })}
            </div>
            <div className="abouts-us-hero-left-img-container"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

const MilestoneCard = ({ value, title, icon }) => {
  return (
    <div className="milestone-card-container">
      <img src={icon} />
      <span>{"+" + value}</span>
      <p>{title}</p>
    </div>
  );
};

export default HeroSection;
