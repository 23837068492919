import React from "react";
import "./DefaultCard.css";
const DefaultCard = ({ title, imgPath }) => {
  return (
    <div className="default-card-container">
      <img src={imgPath} />
    </div>
  );
};

export default DefaultCard;
