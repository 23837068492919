export const servicecList = [
  {
    title: "פיתוח אתרים",

    icon: require("../assets/icons/web-dev.jpg"),
    description:
      "אנו מתמחים ביצירת אתרים מותאמים אישית, שמשלבים יצירתיות וטכנולוגיה מתקדמת על מנת לספק חוויות משתמש מדויקות ומתוחזקות",
  },
  {
    title: "אפיון אתר",
    icon: require("../assets/icons/web-char.jpg"),
    description:
      "תהליך יצירת תוכנית מדויקת לבניית אתר אינטרנט מוצלח, משלב רעיונות, אסטרטגיה ותכנון מעמיק",
  },
  {
    title: "עיצוב אתרים",

    icon: require("../assets/icons/web-design.jpg"),
    description:
      "עיצוב אתרים מקצועי יוצר על ידי צוות מוביל ומציע חווית משתמש מדהימה, משדרת התלהבות, ומגריל את הלקוחות לשוהות באתר לאורך זמן",
  },
  {
    title: "פיתוח אפליקציות",

    icon: require("../assets/icons/mobile-tech.jpg"),
    description:
      "המקום לשבץ רעיונות לחיים דיגיטליים. נבנה אפליקציות מותאמות אישית, מנוהלות מתוך חשיבה אסטרטגית וטכנולוגיה מתקדמת",
  },
  {
    title: `UI\\UX פיתוח `,

    icon: require("../assets/icons/ui-tech.jpg"),
    description:
      "יצירת חוויות משתמש מודרניות וייחודיות. אנו מעצבים ממשקים ובודקים את נוחות המשתמש לספק חוויה מוצלחת",
  },
  {
    title: "ניהול ענן AWS  אמזון",

    icon: require("../assets/icons/cloud.jpg"),
    description:
      " שירותי הענן מובילים לאיכות ויעילות מרבית. אנחנו מספקים פתרונות מתקדמים לניהול ואופטימיזציה של סביבות AWS לעסקים מובילים במגוון תחומים",
  },
  {
    title: "צוותי פיתוח מנוהלים",
    icon: require("../assets/icons/team-tech.jpg"),
    description:
      "אנו הופכים רעיונות לפרויקטים מוכנים, והצוות שלנו משואף לצרכיך כדי להגשים את חלומותיך",
  },
];
export const projects = [
  {
    description:
      "SmartAP - מערכת ואפליקציה לסריקת תעודות וחשבוניות והפיכתם לדיגיטליות ללא צורך בהזנת נתונים ידנית. ",
    img: require("../assets/imgs/project2.png"),
    color: "#96c11f",
  },
  {
    description:
      "Rabbit אפליקציה שכוללת ארבעה מרכיבים: אפליקציית לקוחות, אפליקציית מסעדות ואפליקציית נהגים, ומערכת ניהול.",
    img: require("../assets/imgs/project2.png"),
    color: "#E06731",
  },
  {
    description:
      "Sms4free  היא מערכת ידידותית במיוחד לשליחת SMS כמערכת דיוור הודעות לעסקים",
    img: require("../assets/imgs/project2.png"),
    color: "#08AEEF",
  },
  {
    description:
      "Gomake הוא בית דפוס שבו מייצרים פתרונות אינטראקטיביים מודרניים המסייעים למתג מחדש מותגים ועסקים",
    img: require("../assets/imgs/project2.png"),
    color: "#504fa1",
  },
  {
    description:
      "LAYAN COMMUNICATION פיתוח אתר הכולל בתוכו שתי מערכות מרכזיות המיועדות ללקוחות ולמשווקם לייעול תהליכים",
    img: require("../assets/imgs/project1.png"),
    color: "#2D0D50",
  },

  {
    description: `קרמיקה מואסי בע"מ - ייצור ממשק הזמנות ובו פירוט מלא של המוצרים והמלאי תוך ניהול זמני האספקה בהתאמה אישית על פי דרישות הלקוח`,
    img: require("../assets/imgs/project1.png"),
    color: "#faddc3",
  },
  {
    description:
      "בני עיראקי – בניית מערכת אוטומטית דרכה יוכלו לנהל את ההזמנות, העובדים והמכירות באופן היעיל ביותר.",
    img: require("../assets/imgs/project1.png"),
    color: "#8c0015",
  },
];
