import React from "react";
import "./Projects.css";

import ContactUs from "../../components/ContactUs/ContactUs";
import HeroSection from "./components/HeroSection";
import ProjectsList from "./components/ProjectsList";
// import AppLayout from "../../components/AppLayout";

const Projects = () => {
  return (
    <div>
      <HeroSection />
      <ProjectsList />
      <ContactUs />
    </div>
  );
};

export default Projects;
