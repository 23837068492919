import React from "react";
import "./ContactUs.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CustomeButton from "../CustomeButton";

const ContactUs = () => {
  return (
    <section className="contact-us-container">
      <div className="contect-us-wrapper">
        <header>
          <h2>?רוצים לשמוע עוד</h2>
          <p>
            מלאו את הפרטים ונחזור אליכם בהקדם האפשרי
            <br /> אפשר גם להתקשר אלינו: 04-603-0888 או לכתוב מייל
            contact@codit.co.il
          </p>
        </header>
        <Box
          sx={{
            display: "grid",
            // gridTemplateColumns: { sm: "1fr 1fr 1fr" },
            gap: 2,
            textAlign: "right",
          }}
        >
          <TextField
            label="שם מלא"
            variant="standard"
            required
            sx={{ direction: "ltr" }}
          />
          <TextField label={`דוא"ל`} variant="standard" required />
          <TextField label="מספר טלפון" variant="standard" required />
        </Box>
        <div className="contect-us-btn-container">
          <CustomeButton text={"שלח טופס"} color={"#192841"} />
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
