import { motion } from "framer-motion";

import styles from "../../../styles/style";
import { fadeIn, staggerContainer } from "../../../utils/motion";
import { TypingText } from "../../../components/CustomTexts";
import { arrowDown } from "../../../assets/icons";
import GetStarted from "../../../components/GetStarted";
const About = () => (
  <section className={`${styles.paddings} relative z-10 mb-20`}>
    <div className="gradient-02 z-0" />
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto ${styles.flexCenter} flex-col`}
    >
      <TypingText title="| About Codit" textStyles="text-center text-xl" />

      <motion.p
        variants={fadeIn("up", "tween", 0.2, 1)}
        className="mt-[8px] font-normal sm:text-[32px] text-[20px] text-center text-secondary-white"
      >
        <span className="font-extrabold text-white">At Codit</span>, we are not
        just a software company; we are architects of digital dreams, sculptors
        of code, and visionaries of innovation. Our journey began with a{" "}
        <span className="font-extrabold text-white">passionate</span> of today,
        using only <span className="font-extrabold text-white">belief</span>{" "}
        that every line of code can be a work of art, and every digital
        experience should be a masterpiece{" "}
        <span className="font-extrabold text-white flex-row items-center">
          explore
        </span>
      </motion.p>
      <motion.div
        variants={fadeIn("up", "tween", 0.3, 1)}
        className={`${styles.flexCenter} w-full object-contain mt-[28px]`}
      >
        <GetStarted firstText="More" />
      </motion.div>
      {/* <motion.img
        variants={fadeIn("up", "tween", 0.3, 1)}
        src={arrowDown}
        alt="arrow down"
        className="w-[18px] h-[28px] object-contain mt-[28px]"
      /> */}
    </motion.div>
  </section>
);

export default About;
