import React from "react";
import "./../index.css";
import DefaultHeader from "../components/DefaultHeader/DefaultHeader";
import Timeline from "../../../components/Timeline/Timeline";
import { Fade } from "react-awesome-reveal";
import MarqueeView from "../../../components/Marquee/MarqueeView";
import Lottie from "lottie-react";
import InfoCard from "../../../components/InfoCard/InfoCard";
import DefaultCard from "../components/DefaultCard/DefaultCard";
const timeLineData = [
  {
    mainTitle: "חקר שוק",
    mainDesc:
      "נבצע חקר שוק מקדים בו נלמד יותר על השוק שלכם, נבין מי המתחרים ואיפה אתם עומדים מולם. כך נוכל לבנות את האתר בצורה המקצועית ביותר ולעזור לכם לעקוף את המתחרים.  ",
    description:
      "מפתחים פתרונות לאתגרים טכנולוגיים לתעשייה, למערכות מידע ולעולמות המסחר האלקטרוני.",
    imgPath: require("../../../assets/imgs/market_survey.png"),
    CardComponent: DefaultCard,
  },
  {
    mainTitle: "מיתוג גרפי",
    mainDesc:
      "יצירתיות היא שם המשחק, נבנה לכם נכסים דיגיטליים שישמשו אתכם לאורך כל הדרך העסקית בצורה יצירתית וייחודית לעסק שלכם. בשילוב עם העיצוב הגרפי המנצח שלנו תקבלו תדמית דיגיטלית שתשקף באתר בהתאמה מלאה.",
    imgPath: require("../../../assets/imgs/ui_ux.png"),
    CardComponent: DefaultCard,
  },
  {
    mainTitle: "תוכן",
    description:
      "אנחנו מאמינים שתוכן הוא עיצוב ועיצוב הוא תוכן. לפי עיקרון זה, אנחנו נבנה את תוכן האתר בצורה נוחה למשתמש ושתמשוך יותר לקוחות.",
    CardComponent: InfoCard,
  },
  {
    mainTitle: "תכנון ובניית אתר",
    description:
      "אנחנו משתמשים בטכנולוגיות המתקדמות ביותר בשוק, בשילוב עם נסיון של שנים ולהט לפתירת בעיות נספק לכם אתר ברמה הגבוהה ביותר המותאם לצרכי העסק. ",
    CardComponent: InfoCard,
  },
  {
    mainTitle: "קידום ארגוני וממומן",
    description:
      "רגע לפני שתקבלו את האתר החדש שלכם, נוודא שהוא יהיה מוכן למירוץ האמיתי עם הגדרות SEO (קידום אתרים במנועי חיפוש) הטובות ביותר. הגדרות אלו יעזרו לאתר שלכם לקבל ניקוד גבןכוה יותר במנועי החיפוש וכך להופיע ביותר חיפושים.",

    CardComponent: InfoCard,
  },
];
const categories = [
  {
    name: "פיתוח אתרים",
  },
  {
    name: "פיתוח אתרים",
  },
  {
    name: "פיתוח אתרים",
  },

  {
    name: "פיתוח אתרים",
  },
];
const WebDevelopment = () => {
  const HeaderLeft = () => {
    return (
      <div style={{ width: "20vw", height: "50vh" }}>
        <Lottie
          animationData={require("../../../assets/animations/web.json")}
          loop={true}
          autoplay={true}
        />
      </div>
    );
  };
  return (
    <div
      className="app-dev-container"
      style={{ zIndex: 0, position: "relative" }}
    >
      <DefaultHeader
        title={"פיתוח אתרים"}
        categories={categories}
        animationPath={require("../../../assets/animations/web.json")}
        leftComponent={<HeaderLeft />}
      />

      <div style={{ zIndex: -3, position: "relative" }}>
        <Fade triggerOnce={false}>
          <Timeline timeLineData={timeLineData} />
        </Fade>
      </div>
      <MarqueeView />
    </div>
  );
};

export default WebDevelopment;
