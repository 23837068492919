import { SideBarContext } from "../context/SideBarContext";
import { useContext } from "react";

export const useSidebarContext = () => {
  const context = useContext(SideBarContext);

  if (!context) {
    throw Error(
      "useSideBarContext must be used inside an SideBarContextProvider"
    );
  }

  return context;
};
