import React from "react";
import DefaultHeader from "../components/DefaultHeader/DefaultHeader";
import Timeline from "../../../components/Timeline/Timeline";
import "./../index.css";
import { Fade } from "react-awesome-reveal";
import MarqueeView from "../../../components/Marquee/MarqueeView";
import InfoCard from "../../../components/InfoCard/InfoCard";

import Lottie from "lottie-react";
const timeLineData = [
  {
    title: "מקימים את החברה",
    mainTitle: "2016",
    description:
      "מפתחים פתרונות לאתגרים טכנולוגיים לתעשייה, למערכות מידע ולעולמות המסחר האלקטרוני.",
    CardComponent: InfoCard,
  },
  {
    title: "מקימים את החברה",
    mainTitle: "2017",
    description:
      "מפתחים פתרונות לאתגרים טכנולוגיים לתעשייה, למערכות מידע ולעולמות המסחר האלקטרוני.",
    CardComponent: InfoCard,
  },
  {
    title: "בניית מערכות ענק",
    mainTitle: "2018",
    description:
      "פתרונות דיגיטליים מתקדמים מתוצרתנו ושירות חדשני בעולם התוכנה קידמו בתי עסק וארגונים רבים.",
    CardComponent: InfoCard,
  },
  {
    title: "מקדמים ומתפתחים",
    mainTitle: "2019",
    description:
      "המשכנו לצבור תאוצה והטמענו ידע והכשרות בתחומים חדשים, שהרחיבו את היכולות המקצועיות שלנו.",
    CardComponent: InfoCard,
  },
  {
    title: "עשייה חברתית והתנדבותית",
    mainTitle: "2020",
    description:
      "הצוות שלנו מתחיל להעניק הרצאות, הכשרות והדרכות לאנשים המעוניינים להתחיל לעסוק בתחום, כמו גם לבוגרים.",

    CardComponent: InfoCard,
  },
  {
    title: "מתרחבים לשוק הגלובלי",
    mainTitle: "2021",
    description:
      "גדלנו, והגיע הזמן להתממשק עם חברות ברחבי העולם וליצור שיתופי פעולה, outsourcing ועוד.",

    CardComponent: InfoCard,
  },
  {
    title: "המהפך!",
    mainTitle: "2022",
    description:
      "קודית עוברת שינוי משמעותי, מהפך של ממש, ומתמקדת בטכנולוגיות המתקדמות ביותר תוך השבחה מתמדת של מוצרים ופתרונות דיגיטליים קיימים",

    CardComponent: InfoCard,
  },
];

const ApplicationsDevelopment = () => {
  const categories = [
    {
      name: "שירותי ענן",
    },
    {
      name: "שירותי ענן",
    },
    {
      name: "שירותי ענן",
    },

    {
      name: "שירותי ענן",
    },
  ];

  const HeaderLeft = () => {
    return (
      <div style={{ width: "27vw", height: "50vh" }}>
        <Lottie
          animationData={require("../../../assets/animations/web-server.json")}
          loop={true}
          autoplay={true}
        />
      </div>
    );
  };
  return (
    <div
      className="app-dev-container"
      style={{ zIndex: 0, position: "relative" }}
    >
      <DefaultHeader
        title={"שירותי ענן"}
        categories={categories}
        leftComponent={<HeaderLeft />}
        // animationPath={require("../../../assets/animations/mobile3.json")}
      />
      <div style={{ zIndex: -3, position: "relative" }}>
        <Fade triggerOnce={false}>
          <Timeline timeLineData={timeLineData} />
        </Fade>
      </div>
      <MarqueeView />
    </div>
  );
};

export default ApplicationsDevelopment;
