import React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CustomeButton from "../../../components/CustomeButton";
import {
  EMIAL_ADDRESS,
  LOCATION,
  MOBILE_PHONE,
} from "../../../constants/AppConstants";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { HiPhone } from "react-icons/hi2";
import { FaMapLocationDot } from "react-icons/fa6";
const ContactUsSection = () => {
  return (
    <section className="contact-us-section">
      <div className="contact-us-section-container  max-w-[1480px] w-full gap-5 ">
        <div className="contact-us-info flex gap-10 flex-col">
          <div className="flex flex-col">
            <h1 className="text-4xl font-bold text-white">
              צרו איתנו קשר ונעזור לעסק שלכם
              <span className="text-[var(--app-primary-color)]">.</span>
            </h1>
            <span className="text-lg text-white max-w-[800px]">
              כי חשוב לנו לשמוע מכם, חברתנו מעניקה ללקוחותיה מגוון דרכים ליצירת
              קשר, בכל שאלה, בקשה, פנייה או בירור אתם מוזמנים ליצור אתנו קשר
              ואנחנו נעשה הכול כדי לסייע לכם.
            </span>
          </div>
          <div className="flex gap-5 flex-col">
            <div className="flex items-center gap-5">
              <div className="w-8 h-8 rounded-full bg-[var(--app-primary-color)] flex items-center justify-center">
                <BsFillEnvelopeFill color="white" size={19} />
              </div>
              <span className="font-bold text-white">{MOBILE_PHONE}</span>
            </div>
            <div className="flex items-center gap-5">
              <div className="w-8 h-8 rounded-full bg-[var(--app-primary-color)] flex items-center justify-center">
                <HiPhone color="white" size={18} />
              </div>
              <span className="font-bold text-white">{EMIAL_ADDRESS}</span>
            </div>
            <div className="flex items-center gap-5">
              <div className="w-8 h-8 rounded-full bg-[var(--app-primary-color)] flex items-center justify-center">
                <FaMapLocationDot color="white" size={18} />
              </div>
              <span className="font-bold text-white">{LOCATION}</span>
            </div>
          </div>
        </div>
        <div className="contect-us-section-wrapper">
          <Box
            sx={{
              display: "grid",
              gap: 2,
              textAlign: "right",
            }}
          >
            <div className="flex justify-center flex-col items-center gap-10">
              <h3 className="font-extrabold text-3xl">
                קבע פגישת ייעוץ, והשאר עלינו
              </h3>
              <span className="text-[#8e8f9a] text-lg">
                השאירו לנו הודעה ונחזור אליכם בהקדם האפשרי
              </span>
            </div>

            <TextField
              label="שם מלא"
              variant="standard"
              required
              sx={{ direction: "ltr" }}
            />
            <TextField label={`דוא"ל`} variant="standard" required />
            <TextField label="מספר טלפון" variant="standard" required />
            <TextField label="שם חברה" variant="standard" required />
          </Box>
          <div className="contect-us-section-action-container">
            <CustomeButton text={"שלח טופס"} color={"#192841"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
