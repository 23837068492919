import styles from "../../../styles/style";
import { discount, robot, code } from "../../../assets/icons";
import { motion } from "framer-motion";
import GetStarted from "../../../components/GetStarted";
import { textVariant, staggerContainer } from "../../../utils/motion";

const HeroSection2 = () => {
  return (
    <section
      id="home"
      className={`flex md:flex-row flex-col ${styles.paddingY}`}
    >
      {/* <div className="flex justify-center items-center flex-col relative z-10">
        <motion.h1 variants={textVariant(0.5)} className={styles.heroHeading}>
          IF YOU CAN DREAM IT
        </motion.h1>
        <motion.div
          variants={textVariant(0.5)}
          className="flex flex-row justify-center items-center"
        >
          <h1 className={styles.heroHeading}>WE CAN CO</h1>
          <div className={styles.heroDText} />
          <h1 className={styles.heroHeading}>IT</h1>
        </motion.div>
      </div> */}
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        // className={`${styles.innerWidth} mx-auto flex flex-col`}

        className={`flex-1 ${styles.innerWidth}  ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}
      >
        <div className="flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2">
          <img src={code} alt="discount" className="w-[32px] h-[32px]" />
          <p className={`${styles.paragraph} ml-2`}>
            <span className="text-white">IF</span> YOU CAN DEAM IT WE CAN{" "}
            <span className="text-white">CODIT</span>
          </p>
        </div>

        <div className="flex flex-row justify-between items-center w-full">
          <h1 className="flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] text-white ss:leading-[100.8px] leading-[75px]">
            The Next <br className="sm:block hidden" />{" "}
            <span className="text-gradient">Generation</span>{" "}
          </h1>
          <div className="ss:flex hidden md:mr-4 mr-0">
            <GetStarted firstText="More" secondText="Projects" />
          </div>
        </div>

        <h1 className="font-poppins font-semibold ss:text-[45px] text-[48px] text-white ss:leading-[100.8px] leading-[75px] w-full">
          Of Software Development
        </h1>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          Our team of experts uses a methodology to identify the credit cards
          most likely to fit your needs. We examine annual percentage rates,
          annual fees.
        </p>
      </motion.div>

      <div
        className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}
      >
        {/* <img
          src={robot}
          alt="billing"
          className="w-[100%] h-[100%] relative z-[5]"
        /> */}

        <iframe
          src="https://my.spline.design/macbookprocopy-d0eaa022e1b870ef3a6546efae151216/"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>

        {/* <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
        <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" /> */}
      </div>

      <div className={`ss:hidden ${styles.flexCenter}`}>
        <GetStarted />
      </div>
    </section>
  );
};

export default HeroSection2;
