import { useState } from "react";
import HeroSection from "./components/HeroSection";
import ServicesSection from "./components/ServicesSection";
import "./Home.css";
import ProjectsSection from "./components/ProjectsSection";
import ApplicationSection from "./components/ApplicationSection";
import ContactUs from "../../components/ContactUs/ContactUs";
import QuestionsSection from "./components/QuestionsSection";
import HeroSection2 from "./components/HeroSection2";
import NavBar from "../../components/Navbar/Navbar";
import { useSidebarContext } from "../../hooks/useSidebarContext";
import Spline from "@splinetool/react-spline";
import styles from "../../styles/style";
import About from "./components/About";
const Home = () => {
  const { setIsOpen } = useSidebarContext();
  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };
  const technologiesIcons = [
    { icon: require("../../assets/icons/react.png") },
    { icon: require("../../assets/icons/angular.png") },
    { icon: require("../../assets/icons/html.png") },
    { icon: require("../../assets/icons/aws.png") },
    { icon: require("../../assets/icons/ionic.png") },
    { icon: require("../../assets/icons/jquery.png") },
    { icon: require("../../assets/icons/nodejs.png") },
    // { icon: require("../../../assets/icons/react-native.png") },
  ];

  return (
    <div className="bg-[#1A232E]">
      <div className="home-page-container overflow-hidden">
        <NavBar toggleSidebar={toggleSidebar} color="white" />
        <div className="absolute w-[50%] border-2 h-[100px] inset-0 gradient-01" />
        {/* <HeroSection /> */}
        <div className={`${styles.flexStart}`}>
          <div className={`${styles.boxWidth}`}>
            <HeroSection2 />
          </div>
        </div>
      </div>
      <div className="relative">
        <About />
        <div className="gradient-03 z-0" />
        {/* <Explore /> */}
      </div>

      <ServicesSection />
      <div className="bg-white">
        <ProjectsSection />
        <ApplicationSection />
      </div>

      <ContactUs />
      <QuestionsSection />
    </div>
  );
};

const Keyboard = () => {
  return (
    <div>
      <iframe
        src="https://my.spline.design/fullinteractivekeyboard-33b08290309da41eca5d78501a589400/"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

export default Home;
