import React from "react";
import "./SectionInfo.css";
import CustomeButton from "../../../../components/CustomeButton";
const SectionInfo = ({
  title,
  label,
  description,
  color,
  buttonLabel,
  gradientStyle,
}) => {
  return (
    <div className="section-info">
      <div className={"separator"} style={gradientStyle}></div>
      <h2 style={gradientStyle}>{title}</h2>
      <span>{label}</span>
      <p>{description}</p>
      <CustomeButton text={buttonLabel} color={color} />
    </div>
  );
};

export default SectionInfo;
