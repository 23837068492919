import React from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { v4 as uuid } from "uuid";
const data = [
  {
    label: "E-Commerce",
  },
  {
    label: "CMS",
  },
  {
    label: "UX Design",
  },
  {
    label: "Low Code",
  },
  {
    label: "Unit Testing",
  },
  {
    label: "SEO",
  },
  {
    label: "Animations",
  },
  {
    label: "Clean Code",
  },
  {
    label: "Design Patterns",
  },
  {
    label: "Clean Architecture",
  },
];
const MarqueeContent = () => {
  return (
    <div className="marquee-content-container">
      {data?.map((value, index) => (
        <div key={uuid()} className="marquee-content-container">
          <div>{value?.label}</div>
          <div style={{ display: "flex-row", marginLeft: 50, marginRight: 50 }}>
            <BiChevronLeft />
            <BiChevronRight />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MarqueeContent;
