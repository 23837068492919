import React from "react";
import { RiMenu3Fill } from "react-icons/ri";
import "./Navbar.css";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import ReceiveQuoteButton from "../ReceiveQuoteButton/ReceiveQuoteButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { MOBILE_PHONE } from "../../constants/AppConstants";
import { motion } from "framer-motion";
import { navVariants } from "../../utils/motion";

import classNames from "classnames";
function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

const NavBar = (props) => {
  const [imageWidth, setImageWidth] = useState(110);
  const [scrollWidth, setScrollWidth] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const newImageWidth = scrollY === 0 ? 120 : 120 - scrollY * 0.1;

      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPercentage =
        (scrollY / (documentHeight - windowHeight)) * 100;

      const _imgWidth = scrollPercentage > 0 ? 90 : 110;
      setImageWidth(_imgWidth);
      setScrollWidth(scrollPercentage);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isStickyNav = scrollWidth > 0;

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          position={isStickyNav ? "fixed" : "absolute"}
          className="app-bar"
          style={
            isStickyNav
              ? { background: "white" }
              : { background: "transparent", boxShadow: "none" }
          }
        >
          <div
            className={`navbar-container ${
              isStickyNav || props?.isNavBackgroundWhite
                ? "navbar-container-white"
                : ""
            }`}
          >
            <div className="navbar-inner-container">
              <img
                className="navbar-logo"
                onClick={() => navigate("/")}
                src={require("../../assets/imgs/logo.png")}
                style={{ width: `${imageWidth}px` }}
              />
              <div className="navbar-right">
                <p>
                  <a
                    href="tel:+6030888"
                    style={
                      !isStickyNav && props.color ? { color: props.color } : {}
                    }
                    // className={classNames({
                    //   "color-white border-2": !isStickyNav,
                    // })}
                  >
                    {MOBILE_PHONE}
                  </a>
                </p>
                <ReceiveQuoteButton
                  style={
                    !isStickyNav && props.color ? { color: props.color } : {}
                  }
                />
                <IconButton onClick={props.toggleSidebar}>
                  <RiMenu3Fill
                    size={30}
                    color={!isStickyNav && props.color ? props.color : "black"}
                  />
                </IconButton>
              </div>
            </div>
          </div>
          {scrollWidth > 0 ? (
            <div className="progress-container">
              <div
                className="gradient-progress"
                style={{ width: scrollWidth + "%" }}
              />
            </div>
          ) : null}
        </AppBar>
      </ElevationScroll>
      <Toolbar sx={{ height: 120 }} />
    </>
  );
};

export default NavBar;
