import React, { useState } from "react";

const SocialCard = ({ Icon, color, hoverColor = "white" }) => {
  const [isHovered, setIsHoverd] = useState(false);
  const buttonStyle = {
    "--c": color,
  };
  return (
    <div
      className="social-card-container"
      style={buttonStyle}
      onMouseEnter={() => setIsHoverd(true)}
      onMouseLeave={() => setIsHoverd(false)}
    >
      <Icon color={isHovered ? hoverColor : color} size={25} />
    </div>
  );
};

export default SocialCard;
