import React from "react";
import "./TeamCard.css";
import { IconButton } from "@mui/material";
import { BiLogoLinkedin } from "react-icons/bi";
import { FaLinkedin } from "react-icons/fa";
import { GrFacebookOption } from "react-icons/gr";
import { BsInstagram } from "react-icons/bs";
const TeamCard = ({ description, name, role, imgPath }) => {
  return (
    <div className="team-card">
      {/* <button className="mail">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-mail"
        >
          <rect width="20" height="16" x="2" y="4" rx="2"></rect>
          <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
        </svg>
      </button> */}
      <div className="profile-pic">
        <img src={imgPath} />
      </div>
      <div className="bottom">
        <div className="content">
          <span className="name">{name}</span>
          <span className="name" style={{ fontSize: 14 }}>
            {role}
          </span>
          <span className="about-me">{description}</span>
        </div>
        <div className="bottom-bottom">
          <div className="social-links-container">
            <IconButton>
              <FaLinkedin color="#197FB7" />
            </IconButton>
            <IconButton>
              <GrFacebookOption color="#43619B" />
            </IconButton>
            <IconButton>
              <BsInstagram color="#E7385F" />
            </IconButton>
          </div>
          {/* <button className="button">צור קשר</button> */}
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
