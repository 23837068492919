import React from "react";

import "./ProjectView.css";
import HeroSection from "./components/HeroSection";
import PreyPaySection from "./components/PreyPaySection";
import NavBar from "../../../components/Navbar/Navbar";
import { useSidebarContext } from "../../../hooks/useSidebarContext";
import Projects from "../Projects";
import MoreProjectsSection from "./components/MoreProjectsSection";
import ProjectsList from "../components/ProjectsList";

const ProjectView = () => {
  const { setIsOpen } = useSidebarContext();
  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <div>
      <div className="project-view-main">
        <NavBar toggleSidebar={toggleSidebar} color="white" />
      </div>
      <HeroSection />

      <div className="pt-24 mt-20 projects-hero-section-container">
        <div className="projects-info-row">
          <h1>פרויקטים נוספים</h1>
          <div className="separator"></div>
        </div>
        <div className="projects-info-row"></div>
      </div>

      <ProjectsList />
      {/* <MoreProjectsSection /> */}
    </div>
  );
};

export default ProjectView;
