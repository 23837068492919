import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import TimeLineCard from "../../TimeLineCard/TimeLineCard";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CodeCard from "../../CodeCard.js/CodeCard";
gsap.registerPlugin(ScrollTrigger);

const TimelineItem = ({ card, CardComponent }) => {
  const main = useRef();

  useEffect(() => {
    const ctx = gsap.context((self) => {
      const boxes = self.selector(".time-col");

      boxes.forEach((box) => {
        gsap.to(box, {
          opacity: 1,
          scrollTrigger: {
            trigger: box,
            start: "bottom bottom",
            end: "top 15%",
            scrub: true,
          },
        });
      });
    }, main);
    return () => ctx.revert();
  }, []);

  return (
    // <Fade cascade damping={0.5}>
    <div className="time-line-item-container" ref={main}>
      <div className="time-col time-left">
        <span>{card.mainTitle}</span>
      </div>
      <div className="time-col time-center">
        <div className="time-circle"></div>
      </div>
      <div className="time-col time-right">
        <div className="time-right-content">
          {card.mainDesc ? <span>{card.mainDesc}</span> : null}
          {CardComponent ? (
            <CardComponent
              title={card.title}
              imgPath={card.imgPath}
              description={card.description}
              bgColor={card.bgColor}
              gradientId={card.gradientId}
              svgPath={card.svgPath}
            />
          ) : null}
          {/* <TimeLineCard
            title={card.title}
            description={card.description}
            bgColor={card.bgColor}
            gradientId={card.gradientId}
            svgPath={card.svgPath}
          /> */}
        </div>
      </div>
    </div>
    // </Fade>
  );
};

export default TimelineItem;
