import React from "react";
import TeamCard from "./components/TeamCard/TeamCard";
import "./Team.css";
import HeroSection from "./components/HeroSection";
import MembersList from "./components/MembersList";
import ContactUs from "../../components/ContactUs/ContactUs";
import Ellipse from "../../components/Ellipse/Ellipse";
import MarqueeView from "../../components/Marquee/MarqueeView";
const Team = () => {
  return (
    <div className="team-page-container">
      <HeroSection />
      <MembersList />
      <MarqueeView />
      <ContactUs />
    </div>
  );
};

export default Team;
