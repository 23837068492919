import React from "react";
import "./Ellipse.css";
const Ellipse = ({ backgroundColor, imgPath, custome }) => {
  return (
    <div className="ellipse-container" style={{ backgroundColor }}>
      <img src={imgPath} />
    </div>
  );
};

export default Ellipse;
