import React from "react";

import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { servicecList } from "../../../utils/data";
import TechnologeCard from "../../../components/TechnologeCard/TechnologeCard";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ServicesSection = () => {
  return (
    <section className="about-us-services-section">
      <h3>השירותים שלנו</h3>
      <div className="about-us-services-container">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            justifyContent="center"
            alignItems="center"
          >
            {servicecList.map((service, index) => (
              <TechnologeCard
                key={index}
                icon={service.icon}
                lottie={service.lottie}
                title={service.title}
                description={service.description}
              />
              //   icon, lottie, title, description
            ))}
          </Grid>
        </Box>
      </div>
    </section>
  );
};

export default ServicesSection;
