import React from "react";
import TechnologeCard from "../../../components/TechnologeCard/TechnologeCard";
import { Carousel } from "primereact/carousel";
import { servicecList } from "../../../utils/data";
import { fadeIn, staggerContainer } from "../../../utils/motion";
import { TypingText } from "../../../components/CustomTexts";
import { motion } from "framer-motion";
import styles from "../../../styles/style";

const ServicesSection = () => {
  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <section className="services-section">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto ${styles.flexCenter} flex-col`}
      >
        <div className="services-info-container">
          {/* <h2>טכנולוגיות ושירותים</h2> */}

          {/* <TypingText
            title="| טכנולוגיות ושירותים"
            textStyles="text-center text-xl"
          /> */}
          <motion.h2
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="mt-[8px] font-normal sm:text-[32px] text-[20px] text-center text-secondary-white"
          >
            טכנולוגיות ושירותים
          </motion.h2>
          <div className="services-info-separator"></div>
          <motion.p
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="mt-[8px] font-normal sm:text-[32px] text-[20px] text-center text-secondary-white"
          >
            במקום המוביל לשיפור עסקי ויצירתיות טכנולוגית. אנו מספקים פתרונות
            מתקדמים, פיתוח אפליקציות, אתרים ואפיון אתר ועוד,
            <br /> על מנת לשפר את עסקכם ולענות על כל צרכיכם הטכנולוגיים
          </motion.p>
        </div>
      </motion.div>
      <div className="services-cards-list">
        <Carousel
          value={servicecList.reverse()}
          numVisible={5}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          className="custom-carousel"
          circular
          // autoplayInterval={6000}
          itemTemplate={TechnologeCard}
        />
      </div>
    </section>
  );
};

export default ServicesSection;
