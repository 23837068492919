import React from "react";
import "./DefaultHeader.css";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import Ellipse from "../../../../components/Ellipse/Ellipse";
const DefaultHeader = ({ title, categories, animationPath, leftComponent }) => {
  return (
    <div className="categories-default-header">
      <div className="categories-header-top-layout">
        <h1>{title}</h1>

        <div className="categories-container">
          {categories?.map((category, index) => (
            <React.Fragment key={index}>
              <span>{category?.name}</span>
              {index !== categories.length - 1 && (
                <div className="vertical-separator">|</div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="default-header-info-container">
        <div>
          <div className="text-separator"></div>
          <article>
            אם הגעתם לעמוד הזה כנראה שקיבלתם החלטה למצוא חברת פיתוח אפליקציות
            שתהיה השותפה שלכם.בשביל זה אנחנו פה, השותף הטכנולוגי שלכם, 42 עובדים
            וצוותי פיתוח מומחים בפיתוח אפליקציות בטכנולוגיות PWA, Flutter
            והיבירדיות אשר יהפכו את הרעיון שלכם לאפליקציה להצלחה בינלאומית!
          </article>

          <div>
            <Ellipse
              backgroundColor="rgba(26, 188, 156, 0.14)"
              // imgPath={require("../../../../assets/icons/Programmer-amico.png")}
            />
          </div>
        </div>
        {leftComponent}
      </div>
    </div>
  );
};

export default DefaultHeader;
