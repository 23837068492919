import "./Timeline.css";
import TimelineItem from "./components/TimelineItem";
import TimelineProgress from "./components/TimelineProgress";

const Timeline = ({ timeLineData, title }) => {
  return (
    <section className="time-line-section">
      <div className="time-line-separator time-line-header">
        <h2>{title}</h2>
      </div>
      <div className="time-line-container">
        <div className="time-line-wrapper">
          <TimelineProgress />
          {timeLineData.map((card, index) => (
            <TimelineItem
              card={card}
              key={index}
              CardComponent={card.CardComponent}
            />
          ))}
        </div>
      </div>
      <div className="time-line-separator time-line-footer"></div>
    </section>
  );
};

export default Timeline;
