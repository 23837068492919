//Pages
import Home from "../pages/Home/Home";
import Contactus from "../pages/ContactUs/Contactus";
import AbountUs from "../pages/AbountUs/AbountUs";
import Team from "../pages/Team/Team";
import Projects from "../pages/Projects/Projects";
import ProjectView from "../pages/Projects/sub-pages/ProjectView";
import { Outlet } from "react-router-dom";
import ApplicationsDevelopment from "../pages/CategoriesPages/applications-development/ApplicationsDevelopment";
import WebDevelopment from "../pages/CategoriesPages/web-development/WebDevelopment";
import DesignDevelopment from "../pages/CategoriesPages/design-development/DesignDevelopment";
import WebCharacterization from "../pages/CategoriesPages/web-characterization/WebCharacterization";
import CloudServices from "../pages/CategoriesPages/cloud-services/CloudServices";
import WebBuilding from "../pages/CategoriesPages/web-building/WebBuilding";

export const routes = [
  { path: "/", element: <Home /> },
  // { path: "/", element: <ApplicationsDevelopment /> },
  { path: "/contact-us", element: <Contactus /> },
  { path: "/about-us", element: <AbountUs /> },
  { path: "/team", element: <Team /> },
  {
    path: "/projects",
    element: <Projects />,
    subs: [
      {
        path: "project-view",
        element: <ProjectView />,
      },
    ],
  },
  {
    path: "/app-dev",
    element: <ApplicationsDevelopment />,
  },
  {
    path: "/web-dev",
    element: <WebDevelopment />,
  },
  {
    path: "/web-design",
    element: <DesignDevelopment />,
  },
  {
    path: "/cloud-services",
    element: <CloudServices />,
  },
  {
    path: "/web-building",
    element: <WebBuilding />,
  },
  {
    path: "/web-characterization",
    element: <WebCharacterization />,
  },
];
