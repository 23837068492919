import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TeamCard from "./TeamCard/TeamCard";

const members = [
  {
    name: "טארק חוסין",
    role: "Back-end developer",
    description:
      "CODIT's backend wizard. Turns ideas into code, architects seamless applications. Meet the force behind our innovation.",
    imgPath: require("../../../assets/imgs/tarik.png"),
  },
  {
    name: "מוחמד ותד",
    role: "Back-end developer",
    description:
      "Backend whiz, quick coder, relentless worker. Powering CODIT's tech with speed and precision.",
    imgPath: require("../../../assets/imgs/wattad.png"),
  },
  {
    name: "ארקאן ג'בלי",
    role: "Web&Mobile developer",
    description:
      "Web & mobile developer, UI/UX wizard. Crafting seamless experiences that resonate. Meet the architect of innovation.",
    imgPath: require("../../../assets/imgs/arkan.png"),
  },
  {
    name: "עלא גאנמה",
    role: "Front-end developer",
    description:
      "Our frontend virtuoso at CODIT. Silent in action, he crafts captivating user interfaces with unwavering focus.",
    imgPath: require("../../../assets/imgs/alaa.png"),
  },

  {
    name: "יוסף מגאדלה",
    role: "CEO&FOUNDER",
    description:
      "Our frontend virtuoso at CODIT. Silent in action, he crafts captivating user interfaces with unwavering focus.",
    // imgPath: require("../../../assets/imgs/saly.jpg"),
    imgPath: require("../../../assets/imgs/wattad.png"),
  },
  {
    name: "סאלי הדרה",
    role: "Business development manager",
    description:
      "Our frontend virtuoso at CODIT. Silent in action, he crafts captivating user interfaces with unwavering focus.",
    imgPath: require("../../../assets/imgs/saly.png"),
  },
  {
    name: "בדר דקה",
    role: "Back-end developer",
    description:
      "Our frontend virtuoso at CODIT. Silent in action, he crafts captivating user interfaces with unwavering focus.",
    imgPath: require("../../../assets/imgs/alaa.png"),
  },
  {
    name: "ויסאם כבהא",
    role: "Web&Mobile developer",
    description:
      "Our frontend virtuoso at CODIT. Silent in action, he crafts captivating user interfaces with unwavering focus.",
    imgPath: require("../../../assets/imgs/tarik.png"),
  },
];

const MembersList = () => {
  return (
    <div>
      <Box sx={{ flexGrow: 1, paddingBottom: 20 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          justifyContent="center"
          alignItems="center"
        >
          {members.map((member, index) => (
            <TeamCard
              key={member.name}
              imgPath={member.imgPath}
              name={member.name}
              role={member.role}
              description={member.description}
            />
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default MembersList;
