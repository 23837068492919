import Home from "./pages/Home/Home";
import "./styles/app.css";
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import React from "react";
import AppRoutes from "./routes";
import { SideBarContextProvider } from "./context/SideBarContext";
const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({ direction: "rtl" });
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={rtlTheme}>
        <SideBarContextProvider>
          <div className="app-container">
            <AppRoutes />
          </div>
        </SideBarContextProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
