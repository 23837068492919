import React from "react";
import "./ProjectCard.css";
import { useNavigate } from "react-router-dom";
import Link from "antd/es/typography/Link";
import { JackInTheBox } from "react-awesome-reveal";
const ProjectCard = ({ number, description, title, imgPath, color }) => {
  const cardStyle = {
    "--clr": color,
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/projects/project-view");
  };
  return (
    <JackInTheBox triggerOnce={true}>
      <div className="container" style={cardStyle}>
        <div className="drop">
          <div className="content">
            <h2>0{number}</h2>
            <img src={imgPath} />
          </div>
        </div>
        <div className="project-card-info">
          <p>{description}</p>
          <a onClick={handleClick}>לקרוא עוד</a>
          {/* <li>
          <Link to="/"></Link>
        </li> */}
        </div>
      </div>
    </JackInTheBox>
  );
};

export default ProjectCard;
