import React from "react";

const PreyPaySection = () => {
  return (
    <section className="mt-5">
      <div className="preypay-section-container">
        <div className="preypay-card">
          <img src={require("../../../../assets/icons/calendar2.png")} />
          <span>תאריך ביצוע</span>
          <p>אוגוסט 2023</p>
        </div>
        <div className="preypay-card">
          <img src={require("../../../../assets/icons/user2.png")} />
          <span>לקוח</span>
          <p>קרן קהילות</p>
        </div>
        <div className="preypay-card">
          <img src={require("../../../../assets/icons/target.png")} />
          <span>מחלקת שירות</span>
          <p>בניית ופיתוח אתרים</p>
        </div>
      </div>
    </section>
  );
};

export default PreyPaySection;
