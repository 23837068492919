import React from "react";
import "./AbountUs.css";
import HeroSection from "./components/HeroSection";
import InfoSection from "./components/InfoSection";
import Timeline from "../../components/Timeline/Timeline";
import { Fade } from "react-awesome-reveal";
import ServicesSection from "./components/ServicesSection";
import CodeCard from "../../components/CodeCard.js/CodeCard";
import InfoCard from "../../components/InfoCard/InfoCard";

const timeLineData = [
  {
    title: "מקימים את החברה",
    mainTitle: "2016",
    description:
      "מפתחים פתרונות לאתגרים טכנולוגיים לתעשייה, למערכות מידע ולעולמות המסחר האלקטרוני.",
    imgPath: require("../../assets/icons/fast-time.png"),
    CardComponent: InfoCard,
  },
  {
    title: "מקימים את החברה",
    mainTitle: "2017",
    description:
      "מפתחים פתרונות לאתגרים טכנולוגיים לתעשייה, למערכות מידע ולעולמות המסחר האלקטרוני.",
    CardComponent: InfoCard,
  },
  {
    title: "בניית מערכות ענק",
    mainTitle: "2018",
    description:
      "פתרונות דיגיטליים מתקדמים מתוצרתנו ושירות חדשני בעולם התוכנה קידמו בתי עסק וארגונים רבים.",
    CardComponent: InfoCard,
  },
  {
    title: "מקדמים ומתפתחים",
    mainTitle: "2019",
    description:
      "המשכנו לצבור תאוצה והטמענו ידע והכשרות בתחומים חדשים, שהרחיבו את היכולות המקצועיות שלנו.",
    CardComponent: InfoCard,
  },
  {
    title: "עשייה חברתית והתנדבותית",
    mainTitle: "2020",
    description:
      "הצוות שלנו מתחיל להעניק הרצאות, הכשרות והדרכות לאנשים המעוניינים להתחיל לעסוק בתחום, כמו גם לבוגרים.",

    CardComponent: InfoCard,
  },
  {
    title: "מתרחבים לשוק הגלובלי",
    mainTitle: "2021",
    description:
      "גדלנו, והגיע הזמן להתממשק עם חברות ברחבי העולם וליצור שיתופי פעולה, outsourcing ועוד.",

    CardComponent: InfoCard,
  },
  {
    title: "המהפך!",
    mainTitle: "2022",
    description:
      "קודית עוברת שינוי משמעותי, מהפך של ממש, ומתמקדת בטכנולוגיות המתקדמות ביותר תוך השבחה מתמדת של מוצרים ופתרונות דיגיטליים קיימים",

    CardComponent: InfoCard,
  },
];

const AbountUs = () => {
  return (
    <div className="about-us-page" style={{ zIndex: 0, position: "relative" }}>
      <div style={{ overflow: "hidden" }}>
        <HeroSection />
        <InfoSection />
      </div>
      <div style={{ zIndex: -3, position: "relative" }}>
        <Fade triggerOnce={false}>
          <Timeline timeLineData={timeLineData} title="היסטוריה של Codit" />
        </Fade>
      </div>
      <ServicesSection />
    </div>
  );
};

export default AbountUs;
