import React from "react";
import Lottie from "lottie-react";
import CustomeButton from "../../../components/CustomeButton";
import SectionInfo from "./SectionInfo/SectionInfo";
import { Slide } from "react-awesome-reveal";
const ProjectsSection = () => {
  return (
    <section className="intergration-section">
      <div className="intergration-container">
        <Slide direction="left" style={{ width: "55%" }} triggerOnce={true}>
          {/* linear-gradient(to right, #085078, #85d8ce) */}
          <SectionInfo
            title={"PROJECTS"}
            label="יש לנו ניסיון רב באינטגרציות למערכות ליבה בארגונים וחברות"
            color={"#191654"}
            buttonLabel="פרויקטים נוספים"
            gradientStyle={{
              backgroundImage: "linear-gradient(to right, #43c6ac, #191654)",
            }}
            description={
              "לאורך השנים הובלנו תהליכי פיתוח מורכבים של פלטפורמות ומערכות שונות " +
              "בארגונים תוך התממשקות לשכבות שונות של מידע, ועבודה מול Rest API ו Web " +
              "service, כל זאת תוך שמירה על תקן אבטחת מידע מחמיר ISO 27001. בין " +
              "לקוחותינו אפשר לפגוש ארגונים ומוסדות שלטון מוכרים. מזמינים אתכם להתרשם " +
              "ממגוון הפרויקטים ומהניסיון העשיר שלנו"
            }
          />
        </Slide>
        <Slide direction="right" triggerOnce={true} style={{ width: "50%" }}>
          <div className="intergration-right">
            {/* <Lottie
              animationData={require("../../../assets/animations/home3.json")}
              loop={true}
              autoplay={true}
            /> */}
            <img
              className="intergration-dashboard-img"
              src={require("../../../assets/imgs/integrations.png")}
            />
            {/* <iframe
              src="https://my.spline.design/webbeewebsitesplinemodel-8a4ee946d440296828c8240e57cc631a/"
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe> */}
          </div>
        </Slide>
      </div>
    </section>
  );
};

export default ProjectsSection;
