import React from "react";
import "./TechnologeCard.css";
import Lottie from "lottie-react";
const TechnologeCard = ({ icon, lottie, title, description }) => {
  return (
    <div className="technologe-card-container">
      {!lottie ? (
        <img className="technologe-icon" src={icon} />
      ) : (
        <Lottie
          className="technologe-icon"
          animationData={lottie}
          loop={true}
          autoplay={true}
        />
      )}
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
};

export default TechnologeCard;
