import React from "react";
import SectionInfo from "../../Home/components/SectionInfo/SectionInfo";
import Ellipse from "../../../components/Ellipse/Ellipse";

const InfoSection = () => {
  return (
    <section style={{ paddingBottom: 120, background: "white" }}>
      <div className="about-us-info-container">
        <div className="abouts-us-hero-ellipse">
          <Ellipse
            imgPath={require("../../../assets/icons/Programmer-amico.png")}
          />
        </div>
        <div>
          <SectionInfo
            title={"שקיפות ובקרה"}
            label="יש לנו ניסיון רב באינטגרציות למערכות ליבה בארגונים וחברות"
            color={"#191654"}
            buttonLabel="פרויקטים נוספים"
            gradientStyle={{
              backgroundImage:
                "linear-gradient(90deg, hsla(165, 89%, 31%, 1) 0%, hsla(161, 46%, 49%, 1) 100%)",
            }}
            description={
              "לאורך השנים הובלנו תהליכי פיתוח מורכבים של פלטפורמות ומערכות שונות " +
              "בארגונים תוך התממשקות לשכבות שונות של מידע, ועבודה מול Rest API ו Web " +
              "service, כל זאת תוך שמירה על תקן אבטחת מידע מחמיר ISO 27001. בין " +
              "לקוחותינו אפשר לפגוש ארגונים ומוסדות שלטון מוכרים. מזמינים אתכם להתרשם " +
              "ממגוון הפרויקטים ומהניסיון העשיר שלנו"
            }
          />
        </div>
      </div>
    </section>
  );
};

export default InfoSection;
